.p-paginator-element {
    color: var(--button-primary) !important;
    border-radius: var(--border-radius) !important;
}

.p-paginator-current {
    color: var(--button-primary) !important;
    border-radius: var(--border-radius) !important;
}

.p-paginator-page {
    background-color: transparent !important;
    border-color: var(--button-primary) !important;
    color: var(--button-primary) !important;
    transition: 0.15s ease-in-out !important;
}

.p-paginator-page:hover {
    background-color: var(--button-primary-hover) !important;
    color: var(--text-secondary) !important;
}

.p-paginator-page.p-highlight {
    background-color: var(--button-primary) !important;
    color: var(--text-secondary) !important;
    transition: 0.15s ease-in-out !important;
}

.p-paginator-page.p-highlight:hover {
    background-color: var(--button-primary-hover) !important;
}

.p-link:focus {
    box-shadow: var(--focus-ring) !important;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: var(--focus-ring) !important;
}

.p-paginator-pages {
    display: flex;
    gap: 0.3rem;
}

.p-paginator-bottom {
    gap: 0.3rem;
}

.p-dropdown-item.p-highlight {
    background-color: var(--button-primary) !important;
    border-color: var(--button-primary) !important;
}

.p-dropdown-item.p-highlight:hover {
    background-color: var(--button-primary-hover) !important;
    border-color: var(--button-primary-hover) !important;
}
