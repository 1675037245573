@import 'assets/css/ng-select';
@import 'assets/css/p-toggle-button';
@import 'assets/css/p-button.scss';
@import 'assets/css/p-table.scss';
@import 'assets/css/loader';
@import 'assets/css/p-steps';
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

/* You can add global styles to this file, and also import other style files */
body {
    margin: 0;
    height: 100%;
    width: 100%;
}

html {
    font-size: 16px;
    font-family: var(--font-family);
}

.ng-dropdown-panel {
    display: block !important;
    z-index: 100000 !important;
}

.card {
    background-color: white;
    padding: 0.5rem;
    border-radius: var(--border-radius);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}

.red-text {
    color: red;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

:root {
    /* FONT, TEXT */
    --font-family: 'Open Sans', sans-serif !important;
    --text-primary: #515c66;
    --text-secondary: #ffffff;
    --text-tertiary: #a9b0ba;
    --text-active: #4d4e99;
    --text-red: red;

    /* BACKGROUND */
    --background-primary: #3a3b73;
    --background-secondary: #6566c9;
    --background-tertiary: #ececff;
    --background-white: #ffffff;
    --background-readonly: #eeeeee;
    --background-lightgray: #fafafa;

    --background-hover: #e9e9ff;
    --background-active: #e1e1ff;

    --button-primary: #6566c9;
    --button-primary-hover: #5758ac;
    --button-secondary: #64748b;
    --button-secondary-hover: #4d5a6b;
    --button-danger: #dc3545;
    --button-danger-hover: #a52936;
    --button-warning: #ffc107;
    --button-warning-hover: #ecb202;
    --button-info: #007bff;
    --button-info-hover: #006bdd;
    --button-success: #16a34a;
    --button-success-hover: #15803d;

    --focus-ring: 0 0 0 0.2rem rgba(101, 102, 201, 0.5) !important;
    --stroke: #cbced3;

    --table-row-hover: #e8f1f8;
}

.actions-area {
    display: flex;
    justify-content: space-between;

    .actions-left {
        margin-right: auto;
    }

    .actions-right {
        margin-left: auto;
    }
}

.fas {
    font-family: 'Font Awesome 6 Free', emoji !important;
    color: var(--text-primary);
}

::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #c5d2dd;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #96a3b2;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
}
