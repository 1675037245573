.p-button,
.p-button-sm {
    background-color: var(--button-primary) !important;
    border-color: var(--button-primary) !important;
}

.p-button:focus {
    box-shadow: var(--focus-ring) !important;
}

.p-button:hover {
    background-color: var(--button-primary-hover) !important;
    border-color: var(--button-primary-hover) !important;
}

.p-button-secondary {
    background-color: var(--button-secondary) !important;
    border-color: var(--button-secondary) !important;
}

.p-button-secondary:hover {
    background-color: var(--button-secondary-hover) !important;
    border-color: var(--button-secondary-hover) !important;
}

.p-button-danger {
    background-color: var(--button-danger) !important;
    border-color: var(--button-danger) !important;
}

.p-button-danger:hover {
    background-color: var(--button-danger-hover) !important;
    border-color: var(--button-danger-hover) !important;
}

.p-button-success {
    background-color: var(--button-success) !important;
    border-color: var(--button-success) !important;
}

.p-button-success:hover {
    background-color: var(--button-success-hover) !important;
    border-color: var(--button-success-hover) !important;
}

.p-button-warning {
    background-color: var(--button-warning) !important;
    border-color: var(--button-warning) !important;
}

.p-button-warning:hover {
    background-color: var(--button-warning-hover) !important;
    border-color: var(--button-warning-hover) !important;
}

.p-button-info {
    background-color: var(--button-info) !important;
    border-color: var(--button-info) !important;
}

.p-button-info:hover {
    background-color: var(--button-info-hover) !important;
    border-color: var(--button-info-hover) !important;
}
