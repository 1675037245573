.p-togglebutton.p-button.p-highlight {
    background-color: var(--button-success) !important;
    border: none !important;
}

.p-togglebutton.p-button.p-highlight:hover {
    background-color: var(--button-success-hover) !important;
}

.p-togglebutton.p-button {
    background-color: var(--button-secondary) !important;
}

.p-togglebutton.p-button:hover {
    background-color: var(--button-secondary-hover) !important;
}
