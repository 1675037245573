.p-steps .p-steps-item.p-steps-current .p-menuitem-link .p-steps-number {
    background: var(--button-primary) !important;
    border-radius: 50% !important;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    background: var(--button-secondary) !important;
    color: var(--text-secondary) !important;
    border-radius: 50% !important;
    border: none !important;
    user-select: none;
}

.p-steps .p-steps-item.p-steps-current .p-menuitem-link .p-steps-title {
    color: var(--text-primary) !important;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    user-select: none;
}

.p-steps .p-steps-item.p-steps-current .p-menuitem-link:focus {
    box-shadow: none !important;
}
